<template>
    <v-app-bar flat height="70" class="grey lighten-5">
      <div class="d-flex align-center">
        <v-img :src="require('@/assets/shield.png')" height="40" width="40" contain class="mr-3" />
        <v-img :src="require('@/assets/monpatrol.png')" height="30" width="250" contain
          style="background-position: left center;" />
      </div>
      <v-spacer />

      <v-btn v-for="(item, i) in items" :key="i" :to="item.to" class="ml-1 ml-md-7" text>
        <span class="hidden-sm-and-down" v-text="item.text" />
      </v-btn>
    </v-app-bar>
</template>

<script>
  // Utilities
  import { get } from 'vuex-pathify'

  export default {
    name: 'AboutAppBar',

    data: () => ({
      items: [
        { icon: 'mdi-account', text: 'Zaloguj', to: '/users/-/sign-in' }

      ],
    }),

    computed: {
      name: get('route/name')
    }

  }
</script>
