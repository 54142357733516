<template>
    <v-main class="white">
        <v-container fluid class="green-app bg-img"
            :style="{ 'background-image': 'url(' + require('@/assets/green.jpg') + ')' }">
            <v-container class="banner-one">
                <v-row justify="center">

                    <v-col xxl="4" xl="7" lg="8" md="12" sm="7" xs="7" cols="12" class="font-weight-bold screen"
                        align="center" style="word-wrap: break-word">
                        <div class="text-h5-dynamic">
                            Stacja monitorowania nowej generacji
                        </div>
                    </v-col>

                </v-row>
                <v-row class="pl-10" justify="center">
                    <v-col cols="10" class="text-h4 font-weight-medium" align="center">
                        Przenieś biznes na wyższy poziom wykorzystując najnowsze technologie i zwiększ efektywność
                        swojej
                        firmy
                    </v-col>
                </v-row>
                <v-row v-if="false" class="d-flex" justify="center">
                    <v-col cols="12" class="text-h4 font-weight-medium" align="center">
                        <v-img src="@/assets/mac.jpg" aspect-ratio="1.7" class="d-flex "></v-img>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
        <v-container class="white ">
            <v-row class="d-flex">
                <v-col cols="12" xl="3" lg="6" md="6" align="center" fill-height class="d-flex flex-column"
                    v-for="card in mainCards" :key="card.title">
                    <about-card :icon=card.icon :title=card.title :description=card.description />
                </v-col>
            </v-row>
        </v-container>
        <v-container id="contact-form" class="pt-10">
            <v-divider class="py-5" />
            <v-row>
                <v-col cols="12" class="text-h3 font-weight-medium" align="center">
                    Skontaktuj się z nami
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="text-h5 font-weight-normal" align="center">
                    Wyślij email na adres: <a href="mailto:info@monpatrol.pl">info@monpatrol.pl</a> lub
                    wypełnij formularz kontaktowy lub, a my skontaktujemy się z
                    Tobą w celu omówienia szczegółów.
                </v-col>
            </v-row>

            <validation-observer v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(contactUs)">
                    <v-row class="d-flex" justify="center">
                        <v-col cols="7" class="text-h5 font-weight-normal" align="center">
                            <validation-provider v-slot="{ errors }" rules="required" :debounce="200">
                                <v-text-field label="Imię i nazwisko" :error-messages="errors" outlined
                                    v-model="form.name" />
                            </validation-provider>
                            <validation-provider v-slot="{ errors }" rules="required" :debounce="200">
                                <v-text-field label="Nazwa firmy" outlined v-model="form.company"
                                    :error-messages="errors" />
                            </validation-provider>
                            <v-text-field label="Adres" outlined v-model="form.address" />
                            <validation-provider v-slot="{ errors }" rules="required|email" :debounce="200">
                                <v-text-field label="Email" :error-messages="errors" outlined v-model="form.email" />
                            </validation-provider>
                            <v-text-field label="Telefon" outlined v-model="form.phone" />
                            <validation-provider v-slot="{ errors }" rules="required" :debounce="200">
                                <v-textarea label="Wiadomość" outlined v-model="form.message"
                                    :error-messages="errors" />
                            </validation-provider>
                            <v-btn color="blue" class="white--text" type="submit" :loading="saving">Wyślij</v-btn>
                        </v-col>
                    </v-row>
                </form>
                <v-dialog v-model="dialog" max-height="500" max-width="390" v-if="dialog">
                    <v-card class="">
                        <v-card-title class="headline" v-if="error != null">
                            Wystąpił błąd
                        </v-card-title>
                        <v-card-title class="headline" v-else>
                            Dziękujemy za kontakt
                        </v-card-title>
                        <v-card-text v-if="error != null">
                            {{ error }}
                        </v-card-text>
                        <v-card-text v-else>
                            Twoja wiadomość została wysłana. Skontaktujemy się z Tobą wkrótce.
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog = false">Zamknij</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </validation-observer>
        </v-container>
    </v-main>
</template>

<script>
import { required, email } from "vee-validate/dist/rules"
import { extend, ValidationObserver, ValidationProvider } from "vee-validate"
import { generalService } from '@/services/general.service';
export default {
    name: 'About',

    mounted() {
        extend("required", {
            ...required,
            message: 'To pole jest wymagane'
        })
        extend("email", {
            ...email,
            message: 'Niepoprawny adres email'
        })
    },
    components: {
        AboutCard: () => import('@/layouts/about/AboutCard'),
    },
    data: () => ({
        dialog: false,
        error: null,
        saving: false,
        form: {
            name: '',
            email: '',
            message: '',
            phone: '',
            company: '',
            address: '',
        },
        mainCards: [
            {
                icon: 'mdi-server',
                title: 'Serwer',
                description: 'Twój serwer, twoje zasoby, twoje zasady. Dostosuj serwer do swoich potrzeb. Pomoże Ci w tym nasz zespół.',
            },
            {
                icon: 'mdi-security',
                title: 'Bezpieczeństwo',
                description: 'Bezpieczeńwo to podstawa. Zapewniamy bezpieczeństwo na najwyższym poziomie.',
            },
            {
                icon: 'mdi-monitor-dashboard',
                title: 'Intuicyjny interfejs',
                description: 'Intuicyjny interfejs pozwala na szybkie i łatwe zarządzanie.',
            },
            {
                icon: 'mdi-cellphone-link',
                title: 'Aplikacja mobilna',
                description: 'Aplikacja stworzona specjalnie dla patroli, serwisantów oraz klientów stacji.',
            },
            {
                title: 'Integracje',
                icon: 'mdi-cogs',
                description: 'Integracje z systemami zewnętrznymi. Nasza stacja monitorująca pozwala na integracje z systemem księgowym, monitoringiem gps i innymi popularnymi stacjami monitorowania.',
            },
            {
                title: 'Raporty',
                icon: 'mdi-file-document',
                description: 'Generowanie raportów. Nasza stacja monitorująca pozwala na generowanie raportów w formacie pdf, csv.',
            },
            {
                title: 'Powiadomienia',
                icon: 'mdi-bell',
                description: 'Wszyscy użytkownicy są informowani o ważnych zdarzeniach za pomocą powiadomień push, sms oraz email.',
            },
            {
                title: 'Customizacja',
                icon: 'mdi-palette',
                description: 'Jeśli zabraknie jakiejś funkcjonalności to zawsze możemy ją dodać. Dostosujemy stację do twoich potrzeb.',
            },

        ],
    }),

    methods: {
        contactUs() {
            console.log('contact us')
            this.saving = true
            generalService.contactUs({
                name: this.form.name,
                email: this.form.email,
                message: this.form.message,
                phone: this.form.phone,
                company: this.form.company,
                address: this.form.address,
            }).then(() => {
                this.form = {
                    name: '',
                    email: '',
                    message: '',
                    phone: '',
                    company: '',
                    address: '',
                }
                this.error = null
                this.dialog = true
                this.saving = false
            }).catch((error) => {
                this.dialog = true
                this.error = error
                this.saving = false
            })
        }
    },
}

</script>

<style>
.bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* height: 100vh; */

}

.green-app {
    background-color: #65B766;
}

div.text-h5-dynamic {
    font-size: 6rem;
    line-height: 1;
}

@media screen and (min-width: 960px) {
    div.text-h5-dynamic {
        font-size: 6rem;
    }
}

@media screen and (max-width: 959px) {
    div.text-h5-dynamic {
        font-size: 3rem;
    }
}
</style>