<template>
  <v-list-item class="mb-0 justify-space-between pl-3">
    <v-list-item-avatar>
      <v-img src="@/assets/shield.png" height="40" width="40" contain />
    </v-list-item-avatar>
    <!-- <v-list-item-avatar>
      <v-img src="@/assets/monpatrol.png" height="40" width="340" contain />
    </v-list-item-avatar> -->
      <v-list-item-content class="pl-2">
      <v-list-item-title class="text-h5">
        <strong class="mr-1">MONPATROL</strong>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  // Utilities
  import { get } from 'vuex-pathify'

  export default {
    name: 'DefaultDrawerHeader',

    computed: { version: get('app/version') },
  }
</script>
