<template>
  <v-container fluid pa-0>
      <router-view />
  </v-container>
</template>

<script>
// Utilities
// import { get } from 'vuex-pathify'

export default {
  name: 'AboutView',

  components: {
    // AboutAppBar: () => import('./AppBar'),
    // AboutFooter: () => import('./Footer'),
  },

  // data: () => ({
  //   srcs: {
  //     '/pages/lock/': 'lock.jpg',
  //     '/pages/login/': 'login.jpg',
  //     '/pages/pricing/': 'pricing.jpg',
  //     '/pages/register/': 'register.jpg',
  //   },
  // }),

  // computed: {
  //   path: get('route/path'),
  //   src () {
  //     return this.srcs[this.path] || 'clint-mckoy.jpg'
  //   },
  // },
}
</script>
