<template>
  <v-container id="dashboard-view" fluid tag="section">
    <v-row>
      <div v-for="(patrol) in patrols.results" :key="`patrol-${patrol.id}`" class="ma-0 pa-1" cols="1">
        <material-small-patrol-card class="fill-height" :patrol="patrol" v-on:click="showMore(patrol)"></material-small-patrol-card>
      </div>
        <v-dialog v-model="dialog" fullscreen
          hide-overlay
          transition="dialog-bottom-transition">
            <material-patrol-card v-on:close="closeMore" :patrol="currentPatrol"></material-patrol-card>
        </v-dialog>
    </v-row>

  </v-container>
</template>

<script>
// Utilities
import { get, call } from 'vuex-pathify'

export default {
  name: 'Dashboard',

  data: () => ({
    dialog: false,
    currentPatrol: null,
    dialogModel: {},
  }),
  watch: {
  },
  computed: {
    patrols: get('dashboard/patrols'),
  },

  methods: {
    refreshX() {
      this.fetchPatrols()
      this.patrols.results.forEach(patrol => {
        if (patrol.id == this.currentPatrol.id) {
          this.currentPatrol = patrol
        }
      })
      if (!this.dialog) {
        clearInterval(this.timer)
      }
    },
    closeMore() {
      this.dialog = false
      clearInterval(this.timer)
    },
    showMore(patrol) {
      this.currentPatrol = patrol
      this.timer = setInterval(this.refreshX, 2500)
      this.dialog = true

    },
    fetchPatrols: call("dashboard/fetchPatrols"),
    fetchPayments: call("payments/fetchPayments"),
  },
  beforeMount() {
    this.fetchPatrols()
    this.fetchPayments()
  },
}
</script>
