import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'
// import { trailingSlash } from '@/util/helpers'
import { layout, route } from '@/util/routes'
import { sync } from 'vuex-pathify';

Vue.use(Router)

const routes = [
  layout('Default', [
    route('Dashboard', { default: 'Events' }),

    route('Event', null, 'event/:id(\\d+)'),
    route('New Event', null, 'event/new'),
    route('Edit Event', { default: 'NewEvent' }, 'event/:eventId/edit'),
    route('Timeline', null, 'components/timeline'),
    route('UserProfile', null, 'components/profile'),

    // Components
    route('Buttons', null, 'components/buttons'),
    route('Grid', null, 'components/grid'),
    route('Tabs', null, 'components/tabs'),
    route('Notifications', null, 'components/notifications'),
    route('Icons', null, 'components/icons'),
    route('Typography', null, 'components/typography'),

    // Forms
    route('Regular Forms', null, 'forms/regular'),
    route('Extended Forms', null, 'forms/extended'),
    route('Validation Forms', null, 'forms/validation'),
    route('Wizard', null, 'forms/wizard'),

    route('New Task', null, 'forms/new-task/:eventId'),
    route(
      'Edit Task Stats',
      { default: 'TaskStats' },
      'site/:siteId/event/:eventId/task/:taskId/edit/stats'
    ),
    route('New Note', { default: 'NewNote' }, 'note/new'),
    route('Edit Note', { default: 'NewNote' }, 'note/:noteId/edit'),
    route('New User', { default: 'NewUser' }, 'users/new'),
    route('User', { default: 'User' }, 'users/:userId', false),
    route('Edit User', { default: 'NewUser' }, 'users/:userId/edit'),
    route(
      'NewWorkingTime',
      { default: 'FormWorkingTime' },
      'working-times/new'
    ),
    route('New Site', { default: 'NewSite' }, 'sites/new'),
    route('Edit Site', { default: 'NewSite' }, 'sites/:siteId/edit'),

    // Tables
    route('Regular Tables', null, 'tables/regular'),
    route('Extended Tables', null, 'tables/extended'),
    route('Data Tables', null, 'tables/data-tables'),
    route('Sites', null, 'sites'),
    route('CodeNames', null, 'codenames'),
    route('Events', null, 'tables/events'),
    route('Interventions', null, 'interventions'),
    route('Notes', null, 'notes'),
    route('Gps', null, 'gps'),
    route('Site', null, 'sites/:siteId'),
    route('Tasks', null, 'sites/:siteId/tasks'),
    route('Site New User', { default: 'NewUser' }, 'sites/:siteId/new-user'),
    route('Working Times', null, 'working-times'),
    route('Users', null, 'users'),

    // Maps
    route('Google Maps', null, 'maps/google'),
    route('Fullscreen Map', null, 'maps/fullscreen'),
    route('Test Map', null, 'maps/testmap'),
    route('Test Component', null, 'test/component'),

    route('Rtl', null, 'pages/rtl'),
    route('Widgets', null, 'widgets'),
    route('Charts', null, 'charts'),
    route('Calendar', null, 'calendar'),
    route(
      'ChangeMyPassword',
      { default: 'EditPassword' },
      'profile/password/edit',
      false
    ),
  ]),
  layout('Users', [
    route('SignIn', null, 'users/-/sign-in', true),
    route('SignUp', null, 'users/-/sign-up', true),
    route('EmailConfirm', null, 'users/-/sign-up/confirm-email/:token', true),
    route('PasswordReset', null, 'users/-/password/reset', true),
    route(
      'PasswordResetConfirm',
      null,
      'users/-/password/reset/confirm/:uid/:token',
      true
    ),
    route('PasswordNew', null, 'users/-/password/new', true),
  ]),
  layout('Privacy', [route('Privacy', null, 'privacy', true)]),
  layout('About' , [route('About', null, 'about', true)]),
  layout(
    'Page',
    [
      route('Error', null, 'error', true),
      route('Lock', null, 'lock'),
      route('Pricing', null, 'pricing'),
    ],
    '/pages'
  ),
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash)
      return {
        selector: to.hash,
      }
    if (savedPosition) return savedPosition
    return {
      x: 0,
      y: 0,
    }
  },
  routes: routes,
})

router.beforeEach((to, from, next) => {
  const authorized = store.getters['auth/isAuthorized']
  if (to.matched.some((record) => record.meta.allowAnonymous)) {
    console.log('anonimous')
    next()
  } else if (!authorized) {
    Vue.$log.info('unauthorized redirect to sign-in from', to.fullPath)
    // for local development and monpatrol.pl domain
    if (process.env.NODE_ENV === 'development' || window.location.host === 'monpatrol.pl') {
      next({
        path: 'about'
      });
    } else {
      next({
        path: '/users/-/sign-in',
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next()
  }
})

router.afterEach((to) => {
  Vue.$log.info('router.afterEach', to.fullPath)
  sync('route/path', to.fullPath)
})

export default router
