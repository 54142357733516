<template>
    <v-card flat>
        <v-icon size="50" align="center" class="black--text pa-4">
            {{ icon }}
        </v-icon>
        <div class="text-h4 font-weight-medium" align="center">
            {{ title }}
        </div>
        <v-card-text class="text-h5 font-weight-normal">
            {{ description }}
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'AboutCard',

    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
    },
}
</script>