<template>
  <validation-provider v-slot="{ errors }" rules="" name="event.code">
    <v-autocomplete
      ref="code"
      dense
      v-model="selected"
      :items="codes"
      :item-text="codeAsString"
      :loading="isLoading"
      :label="$t('event.code')"
      :error-messages="errors"
      :search-input.sync="search"
      outlined
      no-filter
      hide-details="auto"
      return-object
    ></v-autocomplete>
  </validation-provider>
</template>

<script>
import EventService from '@/services/event.service'
export default {
  name: 'CodeCombo',

  beforeMount() {
    if (this.code) {
      this.codes.push(this.code)
    }
    this.selected = this.code
  },
  data: () => ({
    selected: null,
    isLoading: false,
    codes: [],
    search: null,
  }),
  props: {
    autocommit: {
      type: Boolean,
      default: false,
    },
    code: {
      type: Object,
      default: null,
    },
  },
  methods: {
    codeAsString: (item) => item.text,
  },
  watch: {
    code(value) {
      this.codes.push(value)
      this.selected = value
    },
    selected(value) {
      this.$emit('code-selected', value)
    },
    search(value) {
      var query = null
      if (!value) {
        query = null
      // } else if (/^\d{0,}\.{0,}\d{0,}$/.test(value)){
      // query = `code__startswith=${value}`
      } else {
        query = `search=${value.replace(" ", ".* ")}`
      }
      EventService.fetchCodes(query)
      .then((res) => {
        this.codes = res.results
      })
      .finally(() => (this.isLoadingCodes = false))
    }
    // search(value) {
    //   // if (this.codes.length > 0) return
    //   this.isLoading = true;
    //   // Lazily load input items
    //   EventService.fetchCodes(value)
    //     .then((res) => {
    //       this.codes = res.results;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     })
    //     .finally(() => (this.isLoading = false));
    // },
  },
}
</script>
