<template>
  <v-app>
  <!-- <div class="w3-top"> -->
    <!-- <header class="bgimg-1 w3-display-container w3-grayscale-min" id="home">
      <div class="w3-display-left w3-text-white" style="padding:48px">
        <span class="w3-jumbo w3-hide-small">Start something that matters</span><br>
        <span class="w3-xxlarge w3-hide-large w3-hide-medium">Start something that matters</span><br>
        <span class="w3-large">Stop wasting valuable time with projects that just isn't you.</span>
      </div>
      <div class="w3-display-bottomleft w3-text-grey w3-large" style="padding:24px 48px">
        <i class="fa fa-facebook-official w3-hover-opacity"></i>
        <i class="fa fa-instagram w3-hover-opacity"></i>
        <i class="fa fa-snapchat w3-hover-opacity"></i>
        <i class="fa fa-pinterest-p w3-hover-opacity"></i>
        <i class="fa fa-twitter w3-hover-opacity"></i>
        <i class="fa fa-linkedin w3-hover-opacity"></i>
      </div>
      asdsad
    </header> -->
    <!-- <page-view />
    <v-footer id="default-footer" color="transparent" absolute app inset>
      <links />
    </v-footer> -->
    <!-- <default-bar /> -->
    <!-- <default-drawer /> -->

    <!-- asdsad -->
    <about-app-bar />
    <about-view />
    <!-- <about-footer /> -->
    <!-- </div> -->
    </v-app>
</template>

<script>

  export default {
    name: 'AboutLayout',

    components: {
      AboutView: () => import('./View'),
      AboutAppBar: () => import('./AppBar'),
      // AboutFooter: () => import('./Footer')
    },
  }
</script>

<style>
</style>